.hero {

	.title {
		margin-top: 190px;

		h1 {
			text-align: center;
			position: relative;
			z-index: 2;
			width: 100%;
			text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
		}
	}
	

	.portrait {
		height: 360px;
		background: url("/assets/images/hero_portrait.jpg") center center no-repeat;
		background-size: cover;
		position: relative;
		margin-top: -50px;
		z-index: 1;

		a.text {
			display: inline-block;
			text-align: center;
			width: 200px;
			position: absolute;
			bottom: 20px;
			left: 50%;
			margin-left: -95px;
			color: #fff;
			font-size: 17px;
			line-height: 20px;
			font-weight: 300;

			p {
				margin: 0;

				&:first-child {
					margin-bottom: 4px;
				}
			}

			&:hover {
				text-decoration: none;

				strong {
					text-decoration: underline;
				}
			}
		}

		/* Small devices (tablets, 768px and up) */
		@media (min-width: $screen-sm-min) {
			width: 640px;
			height: 346px;
			float: right;
			margin-top: 0;
            
		}
		
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: $screen-md-min) {
			width: 739px;
			height: 400px;
		}
		
		/* Large devices (large desktops, 1200px and up) */
		@media (min-width: $screen-lg-min) {}
	}
}