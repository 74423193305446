.filter-panel {
    background: #fff;
    padding: 10px 10px 0 10px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $screen-sm-min) {
        overflow: visible;
    }

    .container {
        margin-left: -10px;
        /* Medium devices (desktops, 992px and up) */
        @media (min-width: $screen-md-min) {
            margin-left: auto;
        }
    }

    .redeem-page & {
        background: transparent;
    }

    .wrapper {
        white-space: nowrap;
        padding-right: 20px;
    }

    button {
        border: 0;
        outline: 0;
        background: #fff;
        color: #000 !important;
        font-weight: bold !important;
        font-size: 16px;
        padding: 7px;
        display: inline-block;

        .redeem-page & {
            background: transparent;
        }

        &.selected,
        &:hover,
        &:focus {
            background: $filter-panel-color;
            color: #fff !important;
            padding: 7px 15px;
        }
    }

    .nav-pills > li {
        & > a {
            border: 0;
            outline: 0;
            background: #fff;
            color: #000 !important;
            font-weight: bold !important;
            font-size: 16px;
            padding: 7px;
            display: inline-block;
            border-radius: 0;
        }

        &.active {
            & > a {
                background: $filter-panel-color;
                color: #fff !important;
                padding: 7px 15px;
            }
        }
    }
}
