@mixin transition($transition) {
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin box-shadow($boxshadow) {
	-webkit-box-shadow: $boxshadow;
	box-shadow: $boxshadow;
}

$screen-lg-min: 1200px;
$screen-sm-min: 768px;
$screen-md-min: 992px;

body.has-lbox, body.has-lboxFull {
	overflow: hidden;
}

.lbox, .lboxFull {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10000;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	@include transition(all 300ms);

	object {
		width: 45px;
		height: 45px;
	}

	.lbox-content {
		background: #474C52;
		opacity: 0;
		@include transition(all 250ms ease-in);
		color: #fff;
		text-align: center;
		@include box-shadow(0px 0px 16px 0px rgba(0,0,0,0.50));
		margin-left: auto;
		margin-right: auto;
		position: absolute;

		left: 0;
		right: 0;

		@media (min-width: $screen-lg-min) {
			margin-top: 50px;
		}

		h1 {
			color: #fff;
			font-size: 38px;
			line-height: 40px;
		}
	}

	.lbox-close {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		position: absolute;
	}

	#walley-checkout-content {
		background: #fbfbfb;
		border-radius: 5px;
		max-width: 600px;
		margin: 0 auto;
		padding-bottom: 15px;
		border: 1px solid #ccc;
	}
}

.lbox {
	.has-lbox & {
		opacity: 1;
		visibility: visible;
	}

	.lbox-content {
		width: 90%;
		top: -300px;
		padding: 30px 10px;

		.has-lbox & {
			top: 10%;
			opacity: 1;
		}

		h1 {
			font-size: 38px;
			line-height: 40px;
		}

		p {
			font-size: 16px;
			margin-bottom: 50px;
			color: #fff;
		}
		/* Small devices (tablets, 768px and up) */
		@media (min-width: $screen-sm-min) {
		}
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: $screen-md-min) {
			padding: 50px 20px;
			width: 60%;

			.has-lbox & {
				top: 20%;
			}
		}
		/* Large devices (large desktops, 1200px and up) */
		@media (min-width: $screen-lg-min) {
			width: 50%;
		}
	}

	.lbox-close {
		background: #fff;
		top: -21px;
		right: -21px;
		color: $brand-primary;
		font-size: 27px;
	}
}

.lboxFull {
	.has-lboxFull & {
		opacity: 1;
		visibility: visible;
	}

	.lbox-content {
		padding: 30px 15px 70px;
		min-height: 70%;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-lg-min) {
			padding: 50px 30px 70px;
			width: 70%;
			top: 3%;
			max-height: 94%;
			height: auto;
		}

		.has-lboxFull & {
			opacity: 1;
		}

		h1 {
			/*background-color: #06715e;*/
			margin: -30px -30px 40px;
			padding: 30px 85px 30px 30px;
			text-align: left;
			font-size: 24px;

			@media (min-width: $screen-lg-min) {
				margin: -50px -30px 40px;
				font-size: 32px;
			}
		}

		.lbox-body-content {
			flex: 1 1 100%;
		}

		p {
			font-size: 18px;
			margin-bottom: 40px;
			text-align: left;
			line-height: 28px;

			@media (min-width: $screen-sm-min) {
				font-size: 22px;
			}
		}

		.lbox-btn {
			margin-top: 30px;
		}
	}

	.lbox-close {
		top: 21px;
		right: 21px;
		color: #fff;
		font-size: 50px;
	}
}