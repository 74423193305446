/*@font-face {
	font-family: 'BentonSans';
	src: url(' /fonts/BentonSans-Medium.eot');
	src: url(' /fonts/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
		url(' /fonts/BentonSans-Medium.woff2') format('woff2'),
		url(' /fonts/BentonSans-Medium.woff') format('woff'),
		url(' /fonts/BentonSans-Medium.ttf') format('truetype'),
		url(' /fonts/BentonSans-Medium.svg#BentonSans-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BentonSans';
	src: url(' /fonts/BentonSans-Book.eot');
	src: url(' /fonts/BentonSans-Book.eot?#iefix') format('embedded-opentype'),
		url(' /fonts/BentonSans-Book.woff2') format('woff2'),
		url(' /fonts/BentonSans-Book.woff') format('woff'),
		url(' /fonts/BentonSans-Book.ttf') format('truetype'),
		url(' /fonts/BentonSans-Book.svg#BentonSans-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BentonSans';
	src: url(' /fonts/BentonSans-Light.eot');
	src: url(' /fonts/BentonSans-Light.eot?#iefix') format('embedded-opentype'),
		url(' /fonts/BentonSans-Light.woff2') format('woff2'),
		url(' /fonts/BentonSans-Light.woff') format('woff'),
		url(' /fonts/BentonSans-Light.ttf') format('truetype'),
		url(' /fonts/BentonSans-Light.svg#BentonSans-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}*/

body {
	/*font-family: 'BentonSans', Arial, Verdana;*/
	font-family: Arial, Verdana;
}

a:hover {
	text-decoration: none;
}

h1 {
    color: #fff;
    font-size: 38px;
    line-height: 44px;
    font-weight: bold;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        font-size: 38px;
        line-height: 40px;
    }
    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
        font-size: 60px;
        line-height: 65px;
    }
    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: $screen-lg-min) {
    }
}

h2 {
	font-size: 21px;
	color: #474C52;
	line-height: 23px;
	margin: 0 0 20px 0;
	font-weight: normal;

	/* Small devices (tablets, 768px and up) */
	@media (min-width: $screen-sm-min) {
		font-size: 25px;
		line-height: 31px;
	}
}

h3 {
	color: #fff;
	font-size: 25px;
	line-height: 34px;
	font-weight: normal;

	/* Small devices (tablets, 768px and up) */
	@media (min-width: $screen-sm-min) {
		font-size: 30px;
	}
	
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: $screen-md-min) {}
	
	/* Large devices (large desktops, 1200px and up) */
	@media (min-width: $screen-lg-min) {}
}

strong {
	font-weight: 500;
}
