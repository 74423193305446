.select-fancy {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    background: $custom-input-bg; 
    //@include box-shadow(0px 1px 0px 0px #000000, inset 0px 1px 0px 0px #FFFFFF);
    color: #fff;
    border: 0;
    height: 42px;
    line-height: 45px;
    margin-bottom: 20px; 

    :hover {
        color: #000;
    }

    :focus {
        color: #000;
    }

    &, > * {
        cursor: pointer;
    }

    select {
        @include box-sizing(content-box);
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 16px;
        width: 100%;
        height: 36px;
        padding-left: 20px;
        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        user-select: none;
        -webkit-appearance: none;
        -moz-appearance: radio-container;
        appearance: none;
    }

    &:after {
        content: '\232A';
        width: 0;
        position: absolute;
        right: 30px;
        top: -2px;
        pointer-events: none;
        color: #fff;
        font-size: 25px;
        @include rotate(90deg);
    }
}

.quantity-selector {
    display: flex;
    align-items: center;

    .subtract, .add {
        font-size: 17px;
        padding: 0;
        text-align: center;
        height: 42px;
        line-height: 42px;
        width: 29px;
        background: $custom-input-bg;
        position: relative;
        color: #fff;
        //@include box-shadow(0px 1px 0px 0px #000000, inset 0px 1px 0px 0px #FFFFFF);
    }

    .quantity {
        width: 38px;
        height: 42px;
        text-align: center;
        color: #979797;
        font-size: 22px;
        background: #fff;
        padding: 0;
        border: 1px solid #D6D6D6;
        //@include box-shadow(0px 1px 0px 0px #979797);
        border-radius: 3px;
        margin: 0 5px;
        -webkit-appearance: none;
    }
}


.form-control {
    border-radius: 3px;
    height: 48px;
    border: 0;
    //@include box-shadow( 0px -1px 0px 0px rgba(130,130,130,0.50));
    font-size: 16px;
}

.input-group-addon {
    height: 48px;
    border: 0;
    //@include box-shadow( 0px -1px 0px 0px rgba(130,130,130,0.50));
    background: $custom-input-bg;
    font-size: 16px;
    font-weight: 300;
    min-width: 55px;
}

.invisible-price-input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: .75rem;

    input {
        border: 0 none;
        font-weight: 500;
        text-align: right;
        min-width: 0;
        font-size: 1.75rem;
        width: 100%;
        margin-right: .5rem;
        border-radius: 3px;

        &:focus-visible {
            outline: 2px solid #68ae21;
        }
    }
}

.input-range-custom {
    width: 100%;

    .ranger {
        -webkit-appearance: none;
        width: 100%;
        height: 1rem;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .ranger::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #68ae21;
        cursor: pointer;
    }

    .ranger::-moz-range-thumb {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #68ae21;
        cursor: pointer;
    }
}

