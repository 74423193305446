.redeem-page {
	padding-bottom: 20px;

	hr {
		margin: 10px 0 20px 0;
	}

	.redeem-box {
		background: #414141;
		padding: 20px;
		border-radius: 2px;
		margin: 30px 15px 15px;

		input.code {
			width: 100%;
			height: 35px;
			padding-left: 10px;
		}
	}
}