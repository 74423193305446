.checkout {
	.checkbox {
		margin: 30px 0;
		label {
			padding-left: 30px;
		}

		input {
			width: 22px;
			height: 22px;
			margin: -1px 10px 0 -30px;
		}
	}

	.info {
		font-size: 13px;
		margin-bottom: 30px;
		color: #6C6C6C;
	}
}