.basket-page {
    padding-bottom:0;
    /* Small devices (tablets, 768px and up) */
        @media (min-width: $screen-sm-min) {
            padding-bottom:50px;
        }
    
    h2 {
        
        /* Small devices (tablets, 768px and up) */
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }

    

    .added-items {
        margin-top: 30px;
        margin-bottom: 50px;
        .showOnBigScreen { display:none; }

        /* Small devices (tablets, 768px and up) */
        @media (min-width: $screen-sm-min) {
            .showOnBigScreen {
                display:inline; 
                vertical-align:top;
            }
        }

        li {
            background: #fff;
            margin-bottom: 25px;
            padding: 18px;
            @include box-shadow(0px 1px 0px 0px rgba(127,127,127,0.50));

    

            .price {
                margin-bottom: 20px;
                font-size: 16px;
            }
            

            .col {
                /* Small devices (tablets, 768px and up) */
                @media (min-width: $screen-sm-min) {
                    .price {
                        font-size: 16px;
                    }

                    
                }
                /* Medium devices (desktops, 992px and up) */
                @media (min-width: $screen-md-min) {
                    .price {
                        font-size: 20px;
                    }
                }
                /* Large devices (large desktops, 1200px and up) */
                @media (min-width: $screen-lg-min) {
                    min-height: 90px;
                    display: flex;
                    align-items: center;
                    .price {
                        font-size: 25px;
                    }
                    &.flex-end {
                        justify-content: flex-end;
                    }
                }
                
            }


            .img-responsive {
                width: 100%;
                margin-bottom: 7px;
            }

            .actions {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-end;

                .item-remove {
                    margin-top: 20px;
                }
            }
        }
        /* Large devices (large desktops, 1200px and up) */
        @media (min-width: $screen-lg-min) {
            
        }
    }
}