.price-column {
    background: #DFDFDF;
    padding: 20px;
    margin-top: 30px;
    position: relative;
    z-index: 2;

    h2 {
        text-align: left;
        font-size: 25px;
    }
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        padding: 20px 20px 20px 20px;

        &:before {
            visibility: visible;
            height: 3000px;
            width: 2000px;
            left: 0;
            top: -500px;
        }
    }

    .items {
        .freight {
            font-size: 12px;
            color: #7E7E7E;
        }

        .total {
            border-bottom: 1px solid #9F9F9F;
        }

        .discount {
            color: #ff0000;
        }

        li {
            font-size: 16px;
            color: #474C52;
            padding-bottom: 10px;
            padding-top: 10px;




            .icon-tick {
                position: relative;
                top: 2px;
                margin-right: 7px;
            }

            .price {
                float: right;



                .old {
                    font-size: 13px;
                }
            }

            .price-total {
                float: right;
                font-size: 25px;
            }

            &:last-child {
                border: 0;
            }
        }
    }

    form {
        input[type="text"] {
            width: 100%;
            margin-bottom: 5px;
            margin-top: 30px;
            height: 42px;
            border: 0;
            @include box-shadow(inset 0px 1px 0px 0px rgba(122,122,122,0.50));
            background: #F9F9F9;
            border-radius: 3px;
            text-align: center;
            font-size: 16px;
        }

        .btn:last-child {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }
    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
    }
    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: $screen-lg-min) {
    }
}
