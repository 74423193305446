.gift-cards {
    background: #fff;
    padding-top: 10px;

    .redeem-page & {
        background: transparent;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 2px;

            a:hover {
                background: $brand-primary;
                color: white;
            }

            a.gift-card {
                display: block;
                background: #000;
                display: flex;
                align-items: flex-end;
                height: 258px;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    min-width: 100%;
                    min-height: 100%;
                    width: auto;
                    height: auto;
                    opacity: 1;
                    transition: opacity 200ms ease-out;
                }

                &:hover {
                    img {
                        opacity: 0.87;
                    }
                }

                .text {
                    padding: 10px 14px 10px 14px;
                    font-size: 14px;
                    font-weight: bold;
                    position: relative;
                    color: #fff;
                    background: -moz-linear-gradient(90deg, rgba(0,0,0,0.85) 34%, rgba(0,0,0,0.84) 35%, rgba(5,7,8,0.24) 82%, rgba(5,7,8,0) 100%); /* ff3.6+ */
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(5,7,8,0)), color-stop(18%, rgba(5,7,8,0.24)), color-stop(65%, rgba(0,0,0,0.84)), color-stop(66%, rgba(0,0,0,0.85))); /* safari4+,chrome */
                    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.85) 34%, rgba(0,0,0,0.84) 35%, rgba(5,7,8,0.24) 82%, rgba(5,7,8,0) 100%); /* safari5.1+,chrome10+ */
                    background: -o-linear-gradient(90deg, rgba(0,0,0,0.85) 34%, rgba(0,0,0,0.84) 35%, rgba(5,7,8,0.24) 82%, rgba(5,7,8,0) 100%); /* opera 11.10+ */
                    background: -ms-linear-gradient(90deg, rgba(0,0,0,0.85) 34%, rgba(0,0,0,0.84) 35%, rgba(5,7,8,0.24) 82%, rgba(5,7,8,0) 100%); /* ie10+ */
                    background: linear-gradient(0deg, rgba(0,0,0,0.85) 34%, rgba(0,0,0,0.84) 35%, rgba(5,7,8,0.24) 82%, rgba(5,7,8,0) 100%); /* w3c */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#050708', endColorstr='#000000',GradientType=0 ); /* ie6-9 */
                    width: 100%;

                    .price {
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
                /* Small devices (tablets, 768px and up) */
                @media (min-width: $screen-sm-min) {
                    height: 213px;

                    .logo {
                        max-height: 213px;
                        // overflow: hidden;
                    }
                }
                /* Medium devices (desktops, 992px and up) */
                @media (min-width: $screen-md-min) {
                }
                /* Large devices (large desktops, 1200px and up) */
                @media (min-width: $screen-lg-min) {
                }
            }
        }
    }
}
