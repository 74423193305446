.faq {
	p {
		font-size: 13px;
		color: #474C52;
		margin-bottom: 5px;
	}

	.how-col {
		.step-circle {
			width: 54px;
			height: 54px;
			border-radius: 50%;
			background: #474C52;
			color: #fff;
			line-height: 54px;
			text-align: center;
			font-size: 19px;
			color: #fff;
			float: left;
			margin-right: 25px;
		}
	}

	.block {
		
	}

	p.lead {
		font-size: 16px;
		font-weight: 500;
	}
}