.top {
	height: 70px;
	background: $top-bg;
	z-index: 3;
	display: flex;
	justify-content: center;
	top: 0;
	width: 100%;
	position: relative;
	/* Small devices (tablets, 768px and up) */
	@media (min-width: $screen-sm-min) {
		height: 70px;
		margin-bottom: 0;
	}

	@media (min-width: $screen-md-min) {
		top: 20px;
	}


	.logo-wrapper {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 95px;
		float: left;
		//position: relative;
		margin-right: 75px;
		//position: relative;
		//top: 50%;
		//transform: translateY(-50%);
		img {
			width: 100%;
			height: auto;
		}

		@media (min-width: $screen-sm-min) {
			width: 120px;
		}
	}

	.nav-basket {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.btn-redeem {
		@media (min-width: $screen-sm-min) {
			margin-left: 40px;
		}
	}

	.menu {
		height: 40px;
		display: flex;
		margin-bottom: 0;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		position: absolute;
		width: 100%;
		background: $brand-primary;
		top: 70px;
		left: 0;
		margin-left: -1px;
		border-bottom: 1px solid #000;

		li {
			float: left;
			position: relative;
			border-left: 1px solid lighten($brand-primary, 30%);
			flex-grow: 1;
			text-align: center;
			margin: 0;
			height: 14px;

			a {
				display: block;
				font-size: 12px;
				text-transform: uppercase;
				color: #fff;
			}
		}
		/* Small devices (tablets, 768px and up) */
		@media (min-width: $screen-md-min) {
			position: static;
			width: auto;
			background: transparent;
			height: 100%;
			padding: 3px 5px 0 5px;
			border-bottom: 0;

			li {
				border: 0;
				margin: 0 10px;
				height: auto;

				a {
					font-size: 12px;
					color: #fff;
				}
			}
		}
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: $screen-lg-min) {
			li {
				padding: 0 7px;

				a {
					font-size: 12px;
				}
			}
		}
		/* Large devices (large desktops, 1200px and up) */
		@media (min-width: $screen-lg-min) {
			li {

				a {
					//font-size: 12px;
				}
			}
		}
	}

	.menu-mobile {
		height: auto;
		margin-bottom: 0;
		position: absolute;
		width: 100%;
		background: $brand-primary;
		top: 70px;
		left: 0;
		margin-left: -1px;
		border-bottom: 1px solid #000;

		div.col-xs-6 {
			border-left: 1px solid lighten($brand-primary, 30%);
			text-align: center;
			margin: 0;
			/*height: 14px;*/
			margin: 11px 0;

			a {
				display: block;
				font-size: 12px;
				text-transform: uppercase;
				color: #fff;
			}
		}
		/* Small devices (tablets, 768px and up) */
		@media (min-width: $screen-md-min) {
			display: none;

			div.col-xs-6 {
				display: none;

				a {
					display: none;
				}
			}
		}
	}

	.basket-container {
		padding-top: 12px;
		min-width: 82px;
		position: relative;
		height: 70px;
		/* Small devices (tablets, 768px and up) */
		@media (min-width: $screen-sm-min) {
		}
		/* Medium devices (desktops, 992px and up) */
		@media (min-width: $screen-md-min) {
			padding-right: 30px;
			min-width: 130px;
		}
		/* Large devices (large desktops, 1200px and up) */
		@media (min-width: $screen-lg-min) {
		}

		.basket-icon {
			height: 37px;
			text-align: center;
			line-height: 37px;
			color: #000;
			background: #fff;
			border-radius: 10px;
			display: inline-block;
			float: left;
			font-size: 14px;
			font-weight: bold;
			margin-top: 4px;
			position: relative;
			padding: 0 10px;
			margin-left: 15px;
			position: relative;

			&:before {
				display: inline-block;
				content: '';
				height: 37px;
				width: 1px;
				float: left;
				background: #fff;
				position: absolute;
				left: -20px;
				visibility: hidden;
				top: 0 !important;
			}

			&:after {
				display: inline-block;
				content: '';
				height: 18px;
				width: 18px;
				background: url(/images/basket_icon_mobile.png) no-repeat;
				background-size: contain;
				position: relative;
				top: 5px;
				margin-left: 4px;
			}
			/* Small devices (tablets, 768px and up) */
			@media (min-width: $screen-sm-min) {
				font-size: 15px;
				padding: 0 20px;
				border-radius: 20px;
				margin-left: 40px;

				&:before {
					visibility: visible;
				}

				&:after {
					height: 36px;
					width: 36px;
					left: 100%;
					top: 3px;
					position: absolute;
					margin-left: 10px;
					background-image: url(/images/basket_icon_morebox.png);

					@media (min-width: $screen-md-min) {
						//right: -50px;
					}
				}
			}
			/* Medium devices (desktops, 992px and up) */
			@media (min-width: $screen-md-min) {
				font-size: 16px;
			}
		}


		.basket-dropdown {
			background: #F7F7F7;
			border: 1px solid #979797;
			@include box-shadow(0px 2px 26px 0px rgba(0,0,0,0.50));
			padding: 20px 34px 30px 34px;
			position: fixed;
			left: 15px;
			right: 15px;
			font-size: 16px;
			color: #474C52;

			@include transition(all 200ms ease-in);
			top: 70px;
			opacity: 0;
			visibility: hidden;

			.added-items {
				margin: 0 0 20px 0;
				padding: 0;

				li {
					border-bottom: 1px solid #CFCFCF;
					padding: 10px 0;

					&:last-child {
						border: 0;
					}

					.price {
						float: right;

						&.total {
							float: none;
							display: block;
							text-align: right;
							font-size: 25px;
						}
					}

					.icon-delete {
						margin-right: 6px;
						position: relative;
						top: 6px;
					}
				}
			}

			.btn {
				margin-bottom: 10px;
			}
			/* Small devices (tablets, 768px and up) */
			@media (min-width: $screen-sm-min) {
				width: 400px;
				margin: 0;
				position: absolute;
				right: 0;
				left: auto;
			}
		}

		&:hover, &.active {
			.basket-icon {
				//background-image: url('/images/icon_kuvert_hover.png');
				&:before {
					top: 6px;
				}
			}

			.basket-dropdown {
				top: 100px;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
