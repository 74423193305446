.product-view {

	form {
		margin-top: 30px;

		.btn {
			height: 42px;
		}
	}

	hr {
		margin: 25px 0 15px 0;
		background: lighten($custom-input-bg, 20%);
	}
}

/*select::-ms-value {
    background: transparent;
}*/