.btn {
	border-radius: 2.25px;
	border: 0;
	font-size: 13px;
	padding: 8px 15px 5px 15px;
    height: 35px;

    &.rounded {
	    border-radius: 20px; 
	}
}

.btn-primary {
	border-radius: 20px; 
	@include button-variant(#ffffff, $brand-primary, $brand-primary);
}

.btn-gray {
    //@include box-shadow(0px 1px 0px 0px rgba(0,0,0,0.50), inset 0px 1px 0px 0px rgba(131,131,131,0.50));
    @include button-variant(#ffffff, $custom-input-bg, $custom-input-bg);
    color: #fff;
}

.quarter{
    width: 25%;
}