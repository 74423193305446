.confirmation {
	text-align: center;

	object {
		margin-bottom: 30px;
	}

	hr {
		max-width: 70%;
	}

	.btn {
		margin-top: 20px;
	}
}