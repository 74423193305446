$brand-primary: #68ae21;
$custom-input-bg: #a0a0a0;

$top-bg: #D81939;
$service-footer-bg: #414141;
$footer-bg: #D81939;
$filter-panel-color: #D81939;
$logo-img: "/images/norwegian/logo.png";
$background-img: "/images/biolisten/hero.jpg";

.hero {
    background: #ededed url("/images/hero_photo.jpg") center center no-repeat;
    background-size: cover;
    min-height: 500px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.redeem-card-img {
    background-image: url("/images/redeem_code_card.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.top-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.footer-logo {
    background-image: url($logo-img);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;

    @media (min-width: 992px) {
        float: left;
        margin-left: 15px;
    }
}

.main#custom-theme { /*using a custom theme ID makes it possible to get rid of important usage.*/
    background-position: top;
    background-attachment: inherit;

    .top {
        top: 0px;
        position: absolute;
        background-color: transparent;
    }

    .top-content-page {
        min-height: 25rem;
        background: #efefef url(/images/biolisten/hero-small-fixed.png) top no-repeat;
        background-size: cover;
        overflow: hidden;

        @media (min-width: 768px) {
            min-height: 55rem;
        }

        @media (min-width: 1025px) {
            min-height: 100rem;
        }
    }

    .container-page {
        padding: 3% 15px;
    }

    .nav-basket {
        min-height: 70px;
    }

    .accordion {
        border: 1px solid lightgray;
        background: white;
        padding: 1rem;
        margin-bottom: 1rem;
        float: left;
        cursor: pointer;
    }

    .accordion-text {
        max-height: 0;
        transition: max-height 0.5s ease-out;
        overflow: hidden;
        float: left;
    }

    .accordion h4 {
        float: left;
    }

    .accordion-text p {
        margin: 0;
    }

    .accordion-text.is-active {
        max-height: 200px;
        transition: max-height 0.5s ease-in;
    }

    .custom-icon {
        display: inline-block;
        vertical-align: middle;
        background-color: lightgray;
        border-radius: 50%;
    }

    .custom-icon > path {
        fill: white;
        stroke: white;
        stroke-width: 0.1;
        stroke-linejoin: round;
    }

    .custom-icon--minus, .custom-icon--plus {
        float: right;
        width: 19px;
        margin: 9px 20px;
        height: 19px;
    }

    .custom-icon--hidden {
        display: none;
    }

    .faq .how-col .step-circle {
        color: black;
        background: whitesmoke;
    }

    .supplier-list div {
        margin-bottom: 5px;
    }

    .deck--black {
        background-color: black;
        color: white;

        h2 {
            color: white;
        }
    }

    .deck--lightgray {
        background-color: lightgray;
        color: #474C52;

        h2 {
            color: #474C52;
        }
    }

    .deck--white {
        background-color: whitesmoke;
        color: #474C52;

        h2 {
            color: #474C52;
        }
    }

    .redeem-page .redeem-box {
        background: none;
        padding: 0px;
        border-radius: 0px;

        .code {
            color: #474C52;
        }

        .btn {
            margin-top: 10px;

            @media (min-width: 992px) {
                margin-top: 0px;
            }
        }
    }

    .top .menu {
        top: 0;
        background-color: transparent;
        border-bottom: none;

        li {
            border-left: none;
        }
    }

    .supplier-list {
        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 100%; /* account for borders */
            width: 100%;
            list-style-type: none;
            margin: 0px;
            padding: 0px;

            @media (min-width: 992px) {
                height: 760px;
            }

            @media (min-width: 1200px) {
                height: 530px;
            }
        }

        li {
            width: 100%;
            margin: 5px 30px 5px 0px;

            @media (min-width: 992px) {
                width: 50%;
            }

            @media (min-width: 1200px) {
                width: 33.3%;
            }
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10 & IE11 CSS styles go here */
        .ie1011 {
            margin-left: 0px;
        }
    }
}


.cc-btn {
    background-color: $brand-primary !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
