.how-to-footer {
	background: #474C52;
	padding: 40px;
	position: relative;
	z-index: 3;

	h3 {
		margin: 0;
	}

	.step-circle {
		width: 72px;
		height: 72px;
		background: #70757B;
		line-height: 72px;
		text-align: center;
		font-size: 25px;
		color: #fff;
		border-radius: 50%;
		margin-bottom: 32px;
		margin-top: 35px;
	}

	p {
		font-size: 16px;
		color: #fff;

		+ p {
			font-size: 13px;
			color: #C1C1C1;
		}
	}

	/* Small devices (tablets, 768px and up) */
	@media (min-width: $screen-sm-min) {}
	
	/* Medium devices (desktops, 992px and up) */
	@media (min-width: $screen-md-min) {
		padding: 60px 40px 120px 40px;

		.step-circle {
			margin-top: 0;
		}

		.wrapper {
			position: relative;
			padding: 0 20px;

			&:after {
				content: '';
				width: 1px;
				background: #665E5E;
				position: absolute;
				height: 100%;
				top: 0;
				right: -10px;
			}
		}

		.col-sm-3:last-child .wrapper:after {
			display: none;
		}
	}
	
	/* Large devices (large desktops, 1200px and up) */
	@media (min-width: $screen-lg-min) {}
}