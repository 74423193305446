section.service-footer {
    background: $service-footer-bg;
    color: #fff;
    padding: 40px 20px;

    strong {
        font-size: 16px;
    }

    ul {
        margin: 10px 0 0 15px;
        padding: 0;
        list-style-type: square;

        li {
            a {
                color: #fff;
                font-size: 12px;
                letter-spacing: 0.9px;

                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
}

footer {
    background: $footer-bg;
    padding: 20px;
    color: #fff;
    font-size: 13px;
    line-height: 18px;
    position: relative;
    z-index: 3;
    text-align: center;

    img {
        width: 130px !important;
        margin-right: 30px;
    }

    p {
        margin: 0 20px 0 0;
        display: inline-block;
        position: relative;
        top: 5px;
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: $brand-primary;
        }
    }

    #cardlogos {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 7px;
    }

    #cardlogos img {
        width: auto !important;
        margin-right: auto;
        max-height: 35px;
        margin: 0 20px;
    }
}
